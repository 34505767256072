
import { computed, defineComponent, onMounted, ref } from 'vue';
import 'ol/ol.css';
import './MapContainer.scss';

import { Map, Overlay } from 'ol';
import { createStringXY } from 'ol/coordinate';
import MousePosition from 'ol/control/MousePosition';
import { defaults as defaultControls } from 'ol/control';

import store from '@/store';
import setMapToVuex from '@/composables/common/setMapToVuex';
import karnDistTalukBoundsLoader from '@/composables/common/karnDistTalukBoundsLoader';
import globalToast from '@/composables/common/globalToast';
import handleClicks from '@/composables/mapEvents/handleClicks';
import baseMapLoader from '@/composables/common/baseMapLoader';

import gpBoundsRenderer from '@/composables/gisBounds/gpBoundsRenderer';

import karnVillBoundsLoader from '@/composables/common/karnVillBoundsLoader';
import villageBoundsRenderer from '@/composables/gisBounds/villageBoundsRenderer';

import abadiLimitsLoader from '@/composables/geojsons/abadiLimitsLoader';
import flightLinesLoader from '@/composables/geojsons/flightLinesLoader';
import measureTools from '@/composables/mapEvents/measureTools';

import { defaultAbadiFetchStatusMsg } from '@/store/storeAbadiInfo';
import { defaultAbadiListFetchStatusMsg } from '@/store/storeAbadiFiles';

export default defineComponent({
    setup() {
        const { setMapObjectToVeux } = setMapToVuex();
        const { loadDistTalukKarnBounds } = karnDistTalukBoundsLoader();
        const { showGlobalToast } = globalToast();
        const { handleAbadiClicks } = handleClicks();
        const { loadBaseMapToExtent, unloadBaseMap } = baseMapLoader();

        const { renderGPBounds, surrenderGPBounds } = gpBoundsRenderer();

        const { loadKarnVillBounds } = karnVillBoundsLoader();
        const { surrenderVillageBounds } = villageBoundsRenderer();

        const { loadAbadiLimits, unloadAbadiLimits } = abadiLimitsLoader();
        const { loadFlightlines, unloadFlightlines } = flightLinesLoader();
        const { toggleLineMeasure } = measureTools();

        const g_isLoggedIn = computed(() => store.getters.g_isLoggedIn);
        const g_layerControlsShow = computed(() => store.getters.g_layerControlsShow);
        const g_showPreloader = computed(() => store.getters.g_showPreloader);

        const mapref = ref();
        const popup = ref();

        const loadKarnBoundaryAfterElementLoaded = () => {
            const overlay = new Overlay({
                element: popup.value,
                autoPan: true,
                autoPanAnimation: {
                    duration: 250,
                },
            });
            
            const mousePositionControl = new MousePosition({
                coordinateFormat: createStringXY(6),
                projection: 'EPSG:4326'
            });
            
            const map = new Map({
                target: mapref.value,
                overlays: [overlay],
                controls: defaultControls().extend([mousePositionControl]),
            });

            map.on('click', (event: any) => {
                store.commit('ai_abadifetchstatus', defaultAbadiFetchStatusMsg);
                store.commit('ai_abadilistfetchstatus', defaultAbadiListFetchStatusMsg);
                store.commit('ai_currentabadi', {});
                store.commit('fi_flightInfo', {});
                
                map.forEachFeatureAtPixel(event.pixel, (feature: any, layer: any) => {
                    try {
                        const lyrname = layer.get('name');
                        if(lyrname == 'abadilimitslyr'){
                            let attributesData = { ...feature.getProperties() };
                            delete attributesData['geometry'];

                            store.commit('ai_currentabadi', {
                                abadiFeat: feature
                            });

                            handleAbadiClicks(attributesData);
                        } else if (lyrname == 'flightlineslyr'){
                            let attributesData = { ...feature.getProperties() };
                            delete attributesData['geometry'];

                            // console.log(attributesData);

                            setTimeout(() => {
                                store.commit('fi_flightInfo', attributesData);
                                store.commit('fi_flightFeat', feature);     
                            }, 500);
                        }
                    } catch (e) {}
                });
            });
            
            setMapObjectToVeux(map)
            .then(() => {
                loadDistTalukKarnBounds();
            })
            .catch(() => {
                console.log('Error Setting Map Object');
            });
        }

        onMounted(() => {
            loadKarnBoundaryAfterElementLoaded();
        });

        const toggleLayerControls = () => {
            store.dispatch('g_layerControlsShow', !store.getters.g_layerControlsShow);
        }

        const districtsList = computed(() => store.getters.g_districtsList);
        const districtref = ref('');
        const layerref = ref('');

        const loadLayer = () => {
            if(districtref.value == ''){
                showGlobalToast('Select District First');
                return 0;
            }

            switch (layerref.value){
                case 'Base Map':
                    loadBaseMapToExtent();
                    break;
                case 'Gram Panchayat':
                    renderGPBounds(districtref.value);
                    break;
                case 'Villages':
                    loadKarnVillBounds(districtref.value);
                    break;
                case 'Abadi Limits':
                    loadAbadiLimits(districtref.value);
                    break;
                case 'Flight Lines':
                    loadFlightlines(districtref.value);
                    break;
            }
        }

        const unloadLayer = () => {
            switch (layerref.value){
                case 'Base Map':
                    unloadBaseMap();
                    break;
                case 'Gram Panchayat':
                    surrenderGPBounds();
                    break;
                case 'Villages':
                    surrenderVillageBounds();
                    break;
                case 'Abadi Limits':
                    unloadAbadiLimits();
                    break;
                case 'Flight Lines':
                    unloadFlightlines();
                    break;
            }
        }

        const layerscontrols = ref();

        onMounted(() => {
            let btns = layerscontrols.value.getElementsByTagName('button');
            Array.from(btns).forEach((btn: any) => {
                btn.addEventListener('click', () => {
                    store.dispatch('g_layerControlsShow', false);
                })
            });
        });

        const toggleRouterViewContainer = () => {
            store.dispatch('g_routerViewContainerShow', !store.getters.g_routerViewContainerShow);
        }

        return {
            mapref, g_isLoggedIn, g_layerControlsShow, g_showPreloader,
            toggleLayerControls,
            districtsList, districtref, layerref,
            loadLayer, unloadLayer,
            layerscontrols, toggleRouterViewContainer, toggleLineMeasure
        }
    },
})
