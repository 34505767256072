export const storeReports = {
    state: {
        r_dtrDetails: {},
    },
    getters: {
        r_dtrDetails (state: any) { return state.r_dtrDetails },
    },
    mutations: {
        r_dtrDetails (state: any, value: any) { state.r_dtrDetails = value },
    },
    actions: {
        r_dtrDetails (context: any, value: any) { context.commit('r_dtrDetails', value) },
    },
}