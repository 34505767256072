import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';

import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import mapStyler from '@/composables/common/mapStyler';

import { defaultAbadiFetchStatusMsg } from '@/store/storeAbadiInfo';

const abadiLimitsLoader = () => {
    const { showGlobalToast } = globalToast();
    const { abadisStyleFunction } = mapStyler();

    const loadAbadiLimits = (district: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;
        const map = store.getters.g_mapObj;

        let extent = map.getView().calculateExtent();

        let requestObj = {
            type: 'getgeojson',
            request: 'abadilimits',
            district,
            validateusername: username,
            validatepassword: password,
            extent
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });

        ws.addEventListener('message', (event) => {
            // console.log(event.data);
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);
            if (responseObj.requestStatus == 'success'){
                let gj = responseObj.featureCollection;
                console.log(gj);

                if(gj.features != null){
                    setAbadiLimits(gj);

                    store.dispatch('ai_abadiLimitsDistrict', district);
                    store.dispatch('ai_abadiLimitsFeatCollection', gj);
                } else {
                    showGlobalToast('Abadi Limits Not Available...');
                }
            } else {
                showGlobalToast('Error Loading Abadi Limits...');
            }

            ws.close();
            store.dispatch('g_showPreloader', false);
        });

        ws.addEventListener('open', (event) => {
            store.dispatch('g_showPreloader', true);
            ws.send(Buffer.from(JSON.stringify(requestObj)).toString('base64'));
        });
    }

    const setAbadiLimits = (gj: any) => {
        unloadAbadiLimits();

        const map = store.getters.g_mapObj;

        let abadiLimits = new VectorLayer({
            source: new VectorSource({
                features: new GeoJSON({
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                }).readFeatures(gj)
            }),
            style: abadisStyleFunction,
            zIndex: 5
        });

        abadiLimits.set('name', 'abadilimitslyr');

        map.addLayer(abadiLimits);
        map.getView().fit(abadiLimits.getSource().getExtent());
        // map.getView().setZoom(map.getView().getZoom() - 2);
    }

    const unloadAbadiLimits = () => {
        const map = store.getters.g_mapObj;

        try {
            map.getLayers().forEach((layer: any) => {
                if (layer.get('name') != undefined && layer.get('name') === 'abadilimitslyr') {
                    map.removeLayer(layer);
                }
            });
        } catch (e) {}

        store.dispatch('ai_abadifetchstatus', defaultAbadiFetchStatusMsg);
        store.dispatch('ai_currentabadi', {});
    }

    return { loadAbadiLimits, unloadAbadiLimits, setAbadiLimits }
}

export default abadiLimitsLoader;