export const defaultCurrentFeatureObj = { 
    validkml: false, district: '', taluk: '', attributes: {}, certBase64: '', validcertificate: false
};

export const storeAbadiUpload = {
    state: {
        au_currentfeature: defaultCurrentFeatureObj,
        au_currentdistrict: '',
    },
    getters: {
        au_currentfeature (state: any) { return state.au_currentfeature },
        au_currentdistrict (state: any) { return state.au_currentdistrict },
    },
    mutations: {
        au_currentfeature (state: any, value: any) { state.au_currentfeature = value },
        au_currentdistrict (state: any, value: any) { state.au_currentdistrict = value },
    },
    actions: {
        au_currentfeature (context: any, value: any) { context.commit('au_currentfeature', value) },
        au_currentdistrict (context: any, value: any) { context.commit('au_currentdistrict', value) },
    },
}