
import { computed, defineComponent, onMounted, ref } from 'vue';
import './App.scss';

import MapContainer from '@/components/MapContainer.vue';
import RouterViewContainer from '@/components/RouterViewContainer.vue';

import store from '@/store';
import userLoginCheck from '@/composables/profile/userLoginCheck';
export default defineComponent({
  components: {
    MapContainer, RouterViewContainer
  },
  setup() {
    const { sendAuthenticationRequest } = userLoginCheck();
    
    const g_routerViewContainerShow = computed(() => store.getters.g_routerViewContainerShow);
    const g_karnDistTalukBoundsLoaded = computed(() => store.getters.g_karnDistTalukBoundsLoaded);

    const globaltoastmsg = computed(() => store.getters.g_toastMsg);
    const globalToastEl = ref();

    const setTitle = () => {
      document.title = 'SOI - GIS Karnataka';
    }
    
    const setGlobalToastEl = () => {
      store.dispatch('g_toastEL', globalToastEl);
    }
    
    const loadCredentials = () => {
      let globalusername = window.localStorage.getItem('globalusername')!;
      let globalpassword = window.localStorage.getItem('globalpassword')!;
      // console.log(globalusername, globalpassword);
      
      if(globalusername != undefined && globalpassword != undefined){
        sendAuthenticationRequest(globalusername, globalpassword);
      }
    }
    
    onMounted(() => {
      loadCredentials();
    });
    
    onMounted(() => {
      setTitle();
      setGlobalToastEl();
    });
    
    return {
      g_routerViewContainerShow, globaltoastmsg, globalToastEl, g_karnDistTalukBoundsLoaded
    }
  },
})
