import store from '@/store';

const globalToast = () => {
    const showGlobalToast = (msg: string) => {
        store.dispatch('g_toastMsg', msg);

        const globaltoastel = store.getters.g_toastEL;

        globaltoastel.classList.add('show');

        setTimeout(function(){ globaltoastel.classList.remove('show'); }, 6000);
    }

    return { showGlobalToast }
}

export default globalToast;