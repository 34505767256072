import store from '@/store';

import globalToast from '@/composables/common/globalToast';

const droneNumbers = () => {
    const { showGlobalToast } = globalToast();

    const loadDroneNumbers = () => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        let requestObj = {
            type: 'drones',
            request: 'droneslist',
            validateusername: username,
            validatepassword: password
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                store.dispatch('g_droneNumbers', responseObj.droneslist);
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    const addDrone = (newdronenumber: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        let requestObj = {
            type: 'drones',
            request: 'adddrone',
            validateusername: username,
            validatepassword: password,
            newdronenumber
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });

        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);
            
            if(responseObj.requestStatus == 'success') {
                showGlobalToast('Drone Number Added...');
                loadDroneNumbers();
            } else {
                showGlobalToast('Error Adding Drone...');
            }
            
            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(Buffer.from(JSON.stringify(requestObj)).toString('base64'));
        });
    }

    const deleteDrone = (dronetodelete: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        let requestObj = {
            type: 'drones',
            request: 'deletedrone',
            validateusername: username,
            validatepassword: password,
            dronetodelete
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });

        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);
            
            if(responseObj.requestStatus == 'success') {
                showGlobalToast('Drone Number Deleted...');
                loadDroneNumbers();
            } else {
                showGlobalToast('Error Deleting Drone...');
            }
            
            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(Buffer.from(JSON.stringify(requestObj)).toString('base64'));
        });
    }

    return { loadDroneNumbers, addDrone, deleteDrone }
}

export default droneNumbers;