import localForage from 'localforage';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';

import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import mapStyler from '@/composables/common/mapStyler';

const gpBoundsRenderer = () => {
    const { showGlobalToast } = globalToast();
    const { gpStyleFunction } = mapStyler();

    const renderGPBounds = (district: any) => {
        surrenderGPBounds();

        if(!store.getters.g_karnGPBoundsLoaded){
            showGlobalToast('Please Wait...');
            return;
        }

        localForage.getItem('karnGpBoundsObjStr').then((karnGpBoundsObjStr: any) => {
            let karnGpBoundsObj = JSON.parse(karnGpBoundsObjStr);
            startRenderingGP(district, karnGpBoundsObj);
        });
    }

    const startRenderingGP = (district: any, karnGpBoundsObj: any) => {
        const map = store.getters.g_mapObj;
        
        const extent = map.getView().calculateExtent();
        // console.log(district);

        const distFilteredFeatures = karnGpBoundsObj.featureCollection.features.filter((feat: any) => {
            return feat.properties.kgisdist_2 == district
        });

        // console.log(distFilteredFeatures);

        const mod_FeatCol = {
            type: "FeatureCollection",
            features: distFilteredFeatures
        };

        const vsource = new VectorSource({
            features: new GeoJSON({
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            }).readFeatures(mod_FeatCol)
        });

        const extentFilteredFeats = vsource.getFeaturesInExtent(extent);

        setGramPachayats(extentFilteredFeats)
    }

    const setGramPachayats = (extentFilteredFeats: any) => {
        const map = store.getters.g_mapObj;

        let gplyr = new VectorLayer({
            source: new VectorSource({
                features: extentFilteredFeats
            }),
            style: gpStyleFunction,
            zIndex: 3
        });

        gplyr.set('name', 'gplyr');

        map.addLayer(gplyr);
    }

    const surrenderGPBounds = () => {
        const map = store.getters.g_mapObj;

        try {
            map.getLayers().forEach((layer: any) => {
                if (layer.get('name') != undefined && layer.get('name') === 'gplyr') {
                    map.removeLayer(layer);
                }
            });
        } catch (e) {}
    }

    return { renderGPBounds, surrenderGPBounds }
}

export default gpBoundsRenderer;