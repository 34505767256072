import localForage from 'localforage';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';

import store from '@/store';

import mapStyler from '@/composables/common/mapStyler';

const gpBoundsRenderer = () => {
    const { villagesStyleFunction } = mapStyler();

    const renderVillageBounds = (district: any) => {
        surrenderVillageBounds();

        let localForageItemID = `karnVillBoundsObjStr-${district}`;

        localForage.getItem(localForageItemID).then((karnVillBoundsObjStr: any) => {
            let karnVillBoundsObj = JSON.parse(karnVillBoundsObjStr);
            startRenderingVillages(karnVillBoundsObj);
        });
    }

    const startRenderingVillages = (karnVillBoundsObj: any) => {
        const map = store.getters.g_mapObj;
        
        const extent = map.getView().calculateExtent();

        const vsource = new VectorSource({
            features: new GeoJSON({
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            }).readFeatures(karnVillBoundsObj.featureCollection)
        });

        const extentFilteredFeats = vsource.getFeaturesInExtent(extent);

        setVillageBounds(extentFilteredFeats)
    }

    const setVillageBounds = (extentFilteredFeats: any) => {
        const map = store.getters.g_mapObj;

        let villageslyr = new VectorLayer({
            source: new VectorSource({
                features: extentFilteredFeats
            }),
            style: villagesStyleFunction,
            zIndex: 2
        });

        villageslyr.set('name', 'villageslyr');

        map.addLayer(villageslyr);
    }

    const surrenderVillageBounds = () => {
        const map = store.getters.g_mapObj;

        try {
            map.getLayers().forEach((layer: any) => {
                if (layer.get('name') != undefined && layer.get('name') === 'villageslyr') {
                    map.removeLayer(layer);
                }
            });
        } catch (e) {}
    }

    return { renderVillageBounds, surrenderVillageBounds }
}

export default gpBoundsRenderer;