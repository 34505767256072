export const storeUsers = {
    state: {
        u_users: [],
        u_roles: []
    },
    getters: {
        u_users (state: any) { return state.u_users },
        u_roles (state: any) { return state.u_roles },
    },
    mutations: {
        u_users (state: any, value: any) { state.u_users = value },
        u_roles (state: any, value: any) { state.u_roles = value },
    },
    actions: {
        u_users (context: any, value: any) { context.commit('u_users', value) },
        u_roles (context: any, value: any) { context.commit('u_roles', value) },
    },
}