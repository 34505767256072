export const defaultAbadiListFetchStatusMsg = 'Kindly Load Abadi Limits and Click on Them...';

export const storeAbadiFiles = {
    state: {
        af_uploadbtndisabled: false,
        af_fileUploadProgress: 0,
        ai_abadilistfetchstatus: defaultAbadiListFetchStatusMsg,
        af_filesList: [],
    },
    getters: {
        af_uploadbtndisabled (state: any) { return state.af_uploadbtndisabled },
        af_fileUploadProgress (state: any) { return state.af_fileUploadProgress },
        ai_abadilistfetchstatus (state: any) { return state.ai_abadilistfetchstatus },
        af_filesList (state: any) { return state.af_filesList },
    },
    mutations: {
        af_uploadbtndisabled (state: any, value: any) { state.af_uploadbtndisabled = value },
        af_fileUploadProgress (state: any, value: any) { state.af_fileUploadProgress = value },
        ai_abadilistfetchstatus (state: any, value: any) { state.ai_abadilistfetchstatus = value },
        af_filesList (state: any, value: any) { state.af_filesList = value },
    },
    actions: {
        af_uploadbtndisabled (context: any, value: any) { context.commit('af_uploadbtndisabled', value) },
        af_fileUploadProgress (context: any, value: any) { context.commit('af_fileUploadProgress', value) },
        ai_abadilistfetchstatus (context: any, value: any) { context.commit('ai_abadilistfetchstatus', value) },
        af_filesList (context: any, value: any) { context.commit('af_filesList', value) },
    },
}