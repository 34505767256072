export const storeChangeRequests = {
    state: {
        dr_showReasonBox: false,
        dr_changeRequestObj: {},
        dr_changeRequestReason: '',
        dr_changeRequestArry: []
    },
    getters: {
        dr_showReasonBox (state: any) { return state.dr_showReasonBox },
        dr_changeRequestObj (state: any) { return state.dr_changeRequestObj },
        dr_changeRequestReason (state: any) { return state.dr_changeRequestReason },
        dr_changeRequestArry (state: any) { return state.dr_changeRequestArry },
    },
    mutations: {
        dr_showReasonBox (state: any, value: any) { state.dr_showReasonBox = value },
        dr_changeRequestObj (state: any, value: any) { state.dr_changeRequestObj = value },
        dr_changeRequestReason (state: any, value: any) { state.dr_changeRequestReason = value },
        dr_changeRequestArry (state: any, value: any) { state.dr_changeRequestArry = value },
    },
    actions: {
        dr_showReasonBox (context: any, value: any) { context.commit('dr_showReasonBox', value) },
        dr_changeRequestObj (context: any, value: any) { context.commit('dr_changeRequestObj', value) },
        dr_changeRequestReason (context: any, value: any) { context.commit('dr_changeRequestReason', value) },
        dr_changeRequestArry (context: any, value: any) { context.commit('dr_changeRequestArry', value) },
    },
}