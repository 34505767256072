export const storeFlights = {
    state: {
        flu_flightlinekml: { valid: false, attributes: {} },
        flu_showFlightSummaryBox: false,
        fl_currentdroneno: false,
        fl_flightidsfordroneno: false,
    },
    getters: {
        flu_flightlinekml (state: any) { return state.flu_flightlinekml },
        flu_showFlightSummaryBox (state: any) { return state.flu_showFlightSummaryBox },
        fl_currentdroneno (state: any) { return state.fl_currentdroneno },
        fl_flightidsfordroneno (state: any) { return state.fl_flightidsfordroneno },
    },
    mutations: {
        flu_flightlinekml (state: any, value: any) { state.flu_flightlinekml = value },
        flu_showFlightSummaryBox (state: any, value: any) { state.flu_showFlightSummaryBox = value },
        fl_currentdroneno (state: any, value: any) { state.fl_currentdroneno = value },
        fl_flightidsfordroneno (state: any, value: any) { state.fl_flightidsfordroneno = value },
    },
    actions: {
        flu_flightlinekml (context: any, value: any) { context.commit('flu_flightlinekml', value) },
        flu_showFlightSummaryBox (context: any, value: any) { context.commit('flu_showFlightSummaryBox', value) },
        fl_currentdroneno (context: any, value: any) { context.commit('fl_currentdroneno', value) },
        fl_flightidsfordroneno (context: any, value: any) { context.commit('fl_flightidsfordroneno', value) },
    },
}