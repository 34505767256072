import localForage from 'localforage';

import { View } from 'ol';
import { fromLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import LayerGroup from 'ol/layer/Group';

import store from '@/store';

import mapStyler from '@/composables/common/mapStyler';
import globalToast from '@/composables/common/globalToast';

const karnDistTalukBoundsLoader = () => {
    const { showGlobalToast } = globalToast();
    const { districtStyleFunction, talukStyleFunction } = mapStyler();
    const karnDistTalukBoundsHash = '692AD725F7305F1E8BE0E7ECB7E7771C9E4C0F5C56329A16095C362AFE3406FE';

    const loadDistTalukKarnBounds = () => {
        localForage.getItem('karnDistTalukBoundsObjStr').then((karnDistTalukBoundsObjStr: any) => {
            let karnDistTalukBoundsObj = JSON.parse(karnDistTalukBoundsObjStr);
            // console.log(karnBoundsObj);
            if(karnDistTalukBoundsObj.hash == karnDistTalukBoundsHash){
                setDistTalukBounds(karnDistTalukBoundsObj.featureCollections);
            } else {
                localForage.removeItem('karnDistTalukBoundsObjStr').then(() => {
                    loadKarnDistTalukBoundsFromServer();
                });
            }
        }).catch(() => {
            localForage.removeItem('karnDistTalukBoundsObjStr').then(() => {
                loadKarnDistTalukBoundsFromServer();
            });
        });
    }

    const loadKarnDistTalukBoundsFromServer = () => {
        let requestObj = {
            type: 'getgeojson',
            request: 'karndistalukbounds'
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                let karnDistTalukBoundsObj = {
                    hash: karnDistTalukBoundsHash,
                    featureCollections: responseObj.featureCollections
                };

                localForage.setItem('karnDistTalukBoundsObjStr', JSON.stringify(karnDistTalukBoundsObj));
                setDistTalukBounds(karnDistTalukBoundsObj.featureCollections);
            } else {
                console.log('Karn Dist Taluk GJ Error');
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    // This Function is Called From wsClientMsgHandler.ts

    const setDistTalukBounds = (boundsObj: any) => {
        store.dispatch('g_karnDistTalukBoundsLoaded', true);
        
        // console.log(boundsObj);
        const map = store.getters.g_mapObj;
        // console.log(map);

        const { featureCollectionDist, featureCollectionTaluk } = boundsObj;

        const distFeatures = new GeoJSON({
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        }).readFeatures(featureCollectionDist);

        // console.log(distFeatures);

        const talukFeatures = new GeoJSON({
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        }).readFeatures(featureCollectionTaluk);

        // console.log(distFeatures, talukFeatures);

        const g_distGeoFences = distFeatures.map((feat) => {
            return {
                'district': feat.get('kgisdist_1'),
                'geometry': feat.getGeometry()
            }
        });

        // console.log(g_distGeoFences);
        store.dispatch('g_distGeoFences', g_distGeoFences);

        const g_talukGeoFences = talukFeatures.map((feat) => {
            return {
                'district': feat.get('kgisdist_1'),
                'taluk': feat.get('kgistalukn'),
                'geometry': feat.getGeometry()
            }
        });

        // console.log(g_talukGeoFences);
        store.dispatch('g_talukGeoFences', g_talukGeoFences);

        const g_taluksList = talukFeatures.map((feat) => {
            return {
                'district': feat.get('kgisdist_1'),
                'taluk': feat.get('kgistalukn')
            }
        });

        // console.log(g_taluksList);
        store.dispatch('g_taluksList', g_taluksList);

        const districts = distFeatures.map(feature => {
            return feature.getProperties().kgisdist_1;
        });

        // console.log(districts);

        store.dispatch('g_districtsList', districts);

        const distBounds = new VectorLayer({
            source: new VectorSource({ features: distFeatures }),
            style: districtStyleFunction,
            zIndex: 1
        });

        const talukBounds = new VectorLayer({
            source: new VectorSource({ features: talukFeatures }),
            style: talukStyleFunction,
            zIndex: 4
        });

        map.setLayerGroup(new LayerGroup({
            layers: [ distBounds, talukBounds ]
        }));

        map.setView(new View({
            zoom: 6,
            center: fromLonLat([76.50, 13.00]),
            constrainResolution: true
        }));
    }

    return { loadDistTalukKarnBounds }
}

export default karnDistTalukBoundsLoader;