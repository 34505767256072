import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'PROFILE',
    component: () => import('@/views/Profile.vue')
  },
  {
    path: '/abadisupload',
    name: 'ABADIS_UPLOAD',
    component: () => import('@/views/AbadisUpload.vue')
  },
  {
    path: '/abadisdownload',
    name: 'ABADIS_DOWNLOAD',
    component: () => import('@/views/AbadisDownload.vue')
  },
  {
    path: '/abadifilesupload',
    name: 'ABADI_FILES_UPLOAD',
    component: () => import('@/views/AbadiFilesUpload.vue')
  },
  {
    path: '/abadismanager',
    name: 'ABADIS_MANAGER',
    component: () => import('@/views/AbadiManager.vue')
  },
  {
    path: '/abadisearch',
    name: 'ABADI_SEARCH',
    component: () => import('@/views/AbadiSearch.vue')
  },
  {
    path: '/uploadsinfo',
    name: 'DATA_UPLOADS_INFO',
    component: () => import('@/views/DataUploadsInfo.vue')
  },
  {
    path: '/flightsupload',
    name: 'FLIGHTS_UPLOAD',
    component: () => import('@/views/FlightsUpload.vue')
  },
  {
    path: '/flightsmanager',
    name: 'FLIGHTS_MANAGER',
    component: () => import('@/views/FlightsManager.vue')
  },
  {
    path: '/changerequests',
    name: 'CHANGE_REQUESTS',
    component: () => import('@/views/ChangeRequests.vue')
  },
  {
    path: '/dronesmanager',
    name: 'DRONES_MANAGER',
    component: () => import('@/views/Drones.vue')
  },
  {
    path: '/removeflights',
    name: 'REMOVE_FLIGHTS',
    component: () => import('@/views/FlightsRemover.vue')
  },
  {
    path: '/usersmanager',
    name: 'USERS_MANAGER',
    component: () => import('@/views/Users.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
