import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';

const mapStyler = () => {

  const districtStyleFunction = (feature: any) => {
    return [
      new Style({
        stroke: new Stroke({
          color: 'rgba(0, 60, 136, 0.5)',
          width: 5,
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.1)',
        }),
        text: new Text({
          font: '12px Calibri,sans-serif',
          fill: new Fill({ color: 'white' }),
          stroke: new Stroke({
            color: 'rgba(0, 60, 136, 0.5)', width: 15
          }),
          text: feature.get('kgisdist_1')
        })
      })
    ];
  }

  const talukStyleFunction = (feature: any) => {
    return [
      new Style({
        stroke: new Stroke({
          color: 'rgba(255, 140, 0, 0.8)',
          width: 1,
        }),
        text: new Text({
          font: '10px Calibri,sans-serif',
          fill: new Fill({ color: 'white' }),
          stroke: new Stroke({
            color: 'rgba(255, 140, 0, 0.5)', width: 10
          }),
          text: feature.get('kgistalukn')
        })
      })
    ];
  }

  const gpStyleFunction = (feature: any) => {
    return [
      new Style({
        stroke: new Stroke({
          color: 'rgba(100, 55, 0, 0.8)',
          width: 2,
        }),
        text: new Text({
          font: '10px Calibri,sans-serif',
          fill: new Fill({ color: 'white' }),
          stroke: new Stroke({
            color: 'rgba(60, 60, 136, 0.5)', width: 10
          }),
          text: feature.get('kgisgpname')
        })
      })
    ];
  }

  const villagesStyleFunction = (feature: any) => {
    return [
      new Style({
        stroke: new Stroke({
          color: 'rgba(0, 60, 136, 0.8)',
          width: 1,
        }),
        fill: new Fill({
          color: 'rgba(0, 60, 136, 0.1)',
        }),
        text: new Text({
          font: '10px Calibri,sans-serif',
          fill: new Fill({ color: 'white' }),
          stroke: new Stroke({
            color: 'rgba(0, 60, 136, 0.5)', width: 10
          }),
          text: feature.get('kgisvill_2')
        })
      })
    ];
  }

  const abadisStyleFunction = (feature: any) => {
    return [
      new Style({
        stroke: new Stroke({
          color: 'rgba(0, 255, 55, 0.8)',
          width: 1,
        }),
        fill: new Fill({
          color: 'rgba(0, 255, 55, 0.1)',
        }),
        text: new Text({
          font: '10px Calibri,sans-serif',
          fill: new Fill({ color: 'white' }),
          stroke: new Stroke({
            color: 'rgba(0, 60, 136, 0.5)', width: 10
          }),
          text: feature.get('abadilimitname')
        })
      })
    ];
  }

  const flightsStyleFunction = (feature: any) => {
    return [
      new Style({
        stroke: new Stroke({
          color: 'rgba(127, 0, 255, 0.8)',
          width: 1,
        }),
        fill: new Fill({
          color: 'rgba(127, 0, 255, 0.1)',
        }),
        text: new Text({
          font: '10px Calibri,sans-serif',
          fill: new Fill({ color: 'white' }),
          stroke: new Stroke({
            color: 'rgba(0, 60, 136, 0.5)', width: 10
          }),
          // text: feature.get('flightid')
        })
      })
    ];
  }

  return {
    districtStyleFunction, talukStyleFunction, gpStyleFunction, villagesStyleFunction,
    abadisStyleFunction, flightsStyleFunction
  }
}

export default mapStyler