export const storeFlightInfo = {
    state: {
        fi_flightInfo: {},
        fi_flightFeat: {},
    },
    getters: {
        fi_flightInfo (state: any) { return state.fi_flightInfo },
        fi_flightFeat (state: any) { return state.fi_flightFeat },
    },
    mutations: {
        fi_flightInfo (state: any, value: any) { state.fi_flightInfo = value },
        fi_flightFeat (state: any, value: any) { state.fi_flightFeat = value },
    },
    actions: {
        fi_flightInfo (context: any, value: any) { context.commit('fi_flightInfo', value) },
        fi_flightFeat (context: any, value: any) { context.commit('fi_flightFeat', value) },
    },
}