import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';

import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import mapStyler from '@/composables/common/mapStyler';

const abadiLimitsLoader = () => {
    const { showGlobalToast } = globalToast();
    const { flightsStyleFunction } = mapStyler();

    const loadFlightlines = (district: any) => {
        unloadFlightlines();

        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;
        const map = store.getters.g_mapObj;

        let extent = map.getView().calculateExtent();

        let requestObj = {
            type: 'getgeojson',
            request: 'flightlines',
            district,
            validateusername: username,
            validatepassword: password,
            extent
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });

        ws.addEventListener('message', (event) => {
            // console.log(event.data);
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);
            if (responseObj.requestStatus == 'success'){
                let gj = responseObj.featureCollection;
                // console.log(gj);

                if(gj.features != null){
                    setFlightLines(gj);
                } else {
                    showGlobalToast('Flight Lines Not Available...');
                }
            } else {
                showGlobalToast('Error Loading Flight Lines...');
            }

            ws.close();
            store.dispatch('g_showPreloader', false);
        });

        ws.addEventListener('open', (event) => {
            ws.send(Buffer.from(JSON.stringify(requestObj)).toString('base64'));
            store.dispatch('g_showPreloader', true);
        });
    }

    const setFlightLines = (gj: any) => {
        const map = store.getters.g_mapObj;

        let flightlines = new VectorLayer({
            source: new VectorSource({
                features: new GeoJSON({
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                }).readFeatures(gj)
            }),
            style: flightsStyleFunction,
            zIndex: 6
        });

        flightlines.set('name', 'flightlineslyr');

        map.addLayer(flightlines);
        map.getView().fit(flightlines.getSource().getExtent());
        // map.getView().setZoom(map.getView().getZoom() - 3);
    }

    const unloadFlightlines = () => {
        const map = store.getters.g_mapObj;

        try {
            map.getLayers().forEach((layer: any) => {
                if (layer.get('name') != undefined && layer.get('name') === 'flightlineslyr') {
                    map.removeLayer(layer);
                }
            });
        } catch (e) {}
    }

    return { loadFlightlines, unloadFlightlines, setFlightLines }
}

export default abadiLimitsLoader;