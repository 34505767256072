export const defaultAbadiFetchStatusMsg = 'Kindly Load Abadi Limits and Click on Them...';

export const storeAbadiInfo = {
    state: {
        ai_currentabadi: {},
        ai_currentabadiInfo: {},
        ai_currentabadifiles: {},
        ai_abadifetchstatus: defaultAbadiFetchStatusMsg,
        ai_abadiLimitsDistrict: '',
        ai_abadiLimitsFeatCollection: [],
    },
    getters: {
        ai_currentabadi (state: any) { return state.ai_currentabadi },
        ai_currentabadiInfo (state: any) { return state.ai_currentabadiInfo },
        ai_currentabadifiles (state: any) { return state.ai_currentabadifiles },
        ai_abadifetchstatus (state: any) { return state.ai_abadifetchstatus },
        ai_abadiLimitsDistrict (state: any) { return state.ai_abadiLimitsDistrict },
        ai_abadiLimitsFeatCollection (state: any) { return state.ai_abadiLimitsFeatCollection },
    },
    mutations: {
        ai_currentabadi (state: any, value: any) { state.ai_currentabadi = value },
        ai_currentabadiInfo (state: any, value: any) { state.ai_currentabadiInfo = value },
        ai_currentabadifiles (state: any, value: any) { state.ai_currentabadifiles = value },
        ai_abadifetchstatus (state: any, value: any) { state.ai_abadifetchstatus = value },
        ai_abadiLimitsDistrict (state: any, value: any) { state.ai_abadiLimitsDistrict = value },
        ai_abadiLimitsFeatCollection (state: any, value: any) { state.ai_abadiLimitsFeatCollection = value },
    },
    actions: {
        ai_currentabadi (context: any, value: any) { context.commit('ai_currentabadi', value) },
        ai_currentabadiInfo (context: any, value: any) { context.commit('ai_currentabadiInfo', value) },
        ai_currentabadifiles (context: any, value: any) { context.commit('ai_currentabadifiles', value) },
        ai_abadifetchstatus (context: any, value: any) { context.commit('ai_abadifetchstatus', value) },
        ai_abadiLimitsDistrict (context: any, value: any) { context.commit('ai_abadiLimitsDistrict', value) },
        ai_abadiLimitsFeatCollection (context: any, value: any) { context.commit('ai_abadiLimitsFeatCollection', value) },
    },
}
