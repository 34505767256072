import { createStore } from 'vuex';

import { storeGlobal } from './storeGlobal';
import { storeUsers } from './storeUsers';
import { storeAbadiUpload } from './storeAbadiUpload';
import { storeAbadiInfo } from './storeAbadiInfo';
import { storeAbadiFiles } from './storeAbadiFiles';
import { storeFlights } from './storeFlights';
import { storeFlightInfo } from './storeFlightInfo';
import { storeReports } from './storeReports';
import { storeDataUploadsInfo } from './storeDataUploadsInfo';
import { storeChangeRequests } from './storeChangeRequests';

export default createStore({
  modules: {
    storeGlobal, storeUsers, storeAbadiUpload, storeAbadiInfo,
    storeAbadiFiles, storeFlights, storeFlightInfo, storeReports,
    storeDataUploadsInfo, storeChangeRequests
  }
});