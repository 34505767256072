import localForage from 'localforage';

import store from '@/store';
import globalToast from '@/composables/common/globalToast';
import villageBoundsRenderer from '@/composables/gisBounds/villageBoundsRenderer';

const karnVillBoundsLoader = () => {
    const { showGlobalToast } = globalToast();
    const { renderVillageBounds } = villageBoundsRenderer();

    const karnVillBoundsHash = '792AD725F7305F1E8BE0E7ECB7E7771C9E4C0F5C56329A16095C362AFE3406FE';

    const loadKarnVillBounds = (district: any) => {
        let localForageItemID = `karnVillBoundsObjStr-${district}`;

        localForage.getItem(localForageItemID).then((karnVillBoundsObjStr: any) => {
            let karnVillBoundsObj = JSON.parse(karnVillBoundsObjStr);
            if(karnVillBoundsObj.hash != karnVillBoundsHash || karnVillBoundsObj.district == district){
                localForage.removeItem(localForageItemID).then(() => {
                    loadKarnVillBoundsFromServer(district);
                });
            } else {
                renderVillageBounds(district);
            }
        }).catch(() => {
            localForage.removeItem(localForageItemID).then(() => {
                loadKarnVillBoundsFromServer(district);
            });
        });
    }

    const loadKarnVillBoundsFromServer = (district: any) => {
        let requestObj = {
            type: 'getgeojson',
            request: 'karnvillagebounds',
            district
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                let karnVillBoundsObj = {
                    hash: karnVillBoundsHash,
                    featureCollection: responseObj.featureCollection
                };

                let district = responseObj.district;
                let localForageItemID = `karnVillBoundsObjStr-${district}`;

                localForage.setItem(localForageItemID, JSON.stringify(karnVillBoundsObj)).then(() => {
                    renderVillageBounds(district);
                });
            } else {
                console.log('Karn Vills GJ Error');
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    return { loadKarnVillBounds }
}

export default karnVillBoundsLoader;