import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import droneNumbers from '@/composables/drones/droneNumbers';
import karnGpBoundsLoader from '@/composables/common/karnGpBoundsLoader';

const userLoginCheck = () => {
    const { showGlobalToast } = globalToast();
    const { loadDroneNumbers } = droneNumbers();
    const { loadkarnGpBounds } = karnGpBoundsLoader();

    const sendAuthenticationRequest = (username: string, password: string) => {
        let requestObj = {
            type: 'profile',
            request: 'getprofile',
            validateusername: username,
            validatepassword: password
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.validUser){
                doLoggedInRituals(responseObj);
            } else {
                invalidUser();
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    const doLoggedInRituals = (responseObj: any) => {
        store.dispatch('g_isLoggedIn', true);

        store.dispatch('g_userdetails', responseObj.userDetails);

        window.localStorage.setItem('globalusername', responseObj.userDetails.username);
        window.localStorage.setItem('globalpassword', responseObj.userDetails.password);
        
        showGlobalToast('Login Successful...');
        
        loadDroneNumbers();
        loadkarnGpBounds();
    }

    const invalidUser = () => {
        window.localStorage.removeItem('globalusername');
        window.localStorage.removeItem('globalpassword');
        showGlobalToast('Invalid Username / Password...');
    }

    return { sendAuthenticationRequest }
}

export default userLoginCheck;