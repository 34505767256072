export const storeDataUploadsInfo = {
    state: {
        di_abadisuploadsinfo: {},
        di_filesuploadsinfo: {},
    },
    getters: {
        di_abadisuploadsinfo (state: any) { return state.di_abadisuploadsinfo },
        di_filesuploadsinfo (state: any) { return state.di_filesuploadsinfo },
    },
    mutations: {
        di_abadisuploadsinfo (state: any, value: any) { state.di_abadisuploadsinfo = value },
        di_filesuploadsinfo (state: any, value: any) { state.di_filesuploadsinfo = value },
    },
    actions: {
        di_abadisuploadsinfo (context: any, value: any) { context.commit('di_abadisuploadsinfo', value) },
        di_filesuploadsinfo (context: any, value: any) { context.commit('di_filesuploadsinfo', value) },
    },
}