import localForage from 'localforage';

import store from '@/store';
import globalToast from '@/composables/common/globalToast';

const karnGpBoundsLoader = () => {
    const { showGlobalToast } = globalToast();
    const karnGpBoundsHash = '792AD725F7305F1E8BE0E7ECB7E7771C9E4C0F5C56329A16095C362AFE3406FE';

    const loadkarnGpBounds = () => {
        localForage.getItem('karnGpBoundsObjStr').then((karnGpBoundsObjStr: any) => {
            let karnGpBoundsObj = JSON.parse(karnGpBoundsObjStr);
            if(karnGpBoundsObj.hash != karnGpBoundsHash){
                localForage.removeItem('karnGpBoundsObjStr').then(() => {
                    loadkarnGpBoundsFromServer();
                });
            } else {
                store.dispatch('g_karnGPBoundsLoaded', true);
            }
        }).catch(() => {
            localForage.removeItem('karnGpBoundsObjStr').then(() => {
                loadkarnGpBoundsFromServer();
            });
        });
    }

    const loadkarnGpBoundsFromServer = () => {
        let requestObj = {
            type: 'getgeojson',
            request: 'karngpbounds'
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                let karnGpBoundsObj = {
                    hash: karnGpBoundsHash,
                    featureCollection: responseObj.featureCollection
                };

                localForage.setItem('karnGpBoundsObjStr', JSON.stringify(karnGpBoundsObj));
                store.dispatch('g_karnGPBoundsLoaded', true);
            } else {
                console.log('Karn GP GJ Error');
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    return { loadkarnGpBounds }
}

export default karnGpBoundsLoader;