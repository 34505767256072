import store from "@/store";

import globalToast from '@/composables/common/globalToast';

const submitChangeRequests = () => {
    const { showGlobalToast } = globalToast();

    const requestChange = (deletionReasonVModel: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;
        const changerequest = store.getters.dr_changeRequestObj;
        const reason = store.getters.dr_changeRequestReason;

        const requestObj = {
            type: 'changerequests',
            request: 'addchangerequest',
            validateusername: username,
            validatepassword: password,
            changerequest,
            reason
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){               
                if(responseObj.changeStatus == 'alreadypending') {
                    showGlobalToast('Change Request Pending Already. Contact SS(Tech), KGDC, Survey of India');
                } else {
                    showGlobalToast('New Change Request Successfully Submitted...');
                }

                deletionReasonVModel.value = '';
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    return { requestChange }
}

export default submitChangeRequests;