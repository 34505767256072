
import router from '@/router';
import store from '@/store';
import { computed, defineComponent, onMounted, ref } from 'vue';
import './RouterViewContainer.scss';

import globalToast from '@/composables/common/globalToast';
import submitChangeRequests from '@/composables/changeRequests/submitChangeRequests';

export default defineComponent({
    setup() {
        const { showGlobalToast } = globalToast();
        const { requestChange } = submitChangeRequests();

        const g_isLoggedIn = computed(() => store.getters.g_isLoggedIn);

        //  --------------------------------------      Resizing Views      ---------------------------------------
        let isResizing = false, lastDownY = 0, lastOffsetHeight = 0;

        const routercontainerdiv = ref();
        const routeSelectElRef = ref();
        const dragHandleEl = ref();

        onMounted(() => {
            store.dispatch('g_routercontainerEl', routercontainerdiv.value);
            store.dispatch('g_routeSelectElRef', routeSelectElRef.value);

            document.addEventListener('mousedown', (e: any) => {
                if(e.target == dragHandleEl.value){
                    lastDownY = e.clientY;
                    lastOffsetHeight = routercontainerdiv.value.offsetHeight;

                    isResizing = true;
                }
            });

            document.addEventListener('touchstart', (e: any) => {
                if(e.target == dragHandleEl.value){
                    let touchLocation = e.targetTouches[0];
                    lastDownY = touchLocation.pageY;
                    lastOffsetHeight = routercontainerdiv.value.offsetHeight;

                    isResizing = true;
                }
            });

            document.addEventListener('mousemove', (e: any) => {
                if (!isResizing) return;

                if(e.clientY > 100 && e.clientY < 0.90 * window.innerHeight){
                    let mouseMovement = lastDownY - e.clientY;
                    let newHeight = lastOffsetHeight + mouseMovement;
                    routercontainerdiv.value.style.height = `${newHeight}px`;
                }
            });

            dragHandleEl.value.addEventListener('touchmove', (e: any) => {
                e.preventDefault();
            });

            document.addEventListener('touchmove', (e: any) => {
                if (!isResizing) return;

                let touchLocation = e.targetTouches[0];

                if(touchLocation.pageY > 100 && touchLocation.pageY < 0.90 * window.innerHeight){
                    let touchMovement = lastDownY - touchLocation.pageY;
                    let newHeight = lastOffsetHeight + touchMovement;
                    routercontainerdiv.value.style.height = `${newHeight}px`;
                }
            });
        });

        const resizeEnd = () => {
            isResizing = false;
        }

        document.addEventListener('mouseup', resizeEnd);
        document.addEventListener('touchend', resizeEnd);
        document.addEventListener('touchcancel', resizeEnd);

        //  --------------------------------------      Views Select      ---------------------------------------
        const sections = computed(() => store.getters.g_userdetails.sections);
        const sectionSelected = ref('STATS');

        const routeAccordingly = (e: any) => {
            let sectionRoute = e.target.value;
            console.log('Now Routing To', sectionRoute, '...');

            router.push({
                name: sectionRoute
            });
        }

        onMounted(() => {
            sectionSelected.value = <any>router.options.routes.find(r => r.path == window.location.pathname)?.name;
        });

        const dr_showReasonBox = computed(() => store.getters.dr_showReasonBox);
        const changeReason = ref('');

        const closeReasonBox = () => {
            store.dispatch('dr_showReasonBox', false);
        }

        const submitChangeRequest = () => {
            if(changeReason.value == '') {
                showGlobalToast('Please Specify Some Reason');
                return;
            }

            store.dispatch('dr_showReasonBox', false);
            store.dispatch('dr_changeRequestReason', changeReason.value);
            requestChange(changeReason);
        }
        
        return {
            g_isLoggedIn,
            routercontainerdiv, routeSelectElRef, dragHandleEl,
            sections, sectionSelected, routeAccordingly,
            dr_showReasonBox, changeReason,
            closeReasonBox, submitChangeRequest
        }
    },
})
