// const domain = 'localhost';
const domain = '106.51.134.33';

export const storeGlobal = {
    state: {
        g_wssDomain: `${domain}`,
        g_portDir: {
            'abadis': 3051,
            'changerequests': 3052,
            'drones': 3053,
            'files': 3054,
            'flights': 3055,
            'getgeojson': 3056,
            'profile': 3057,
            'reports': 3058,
            'uploadsinfo': 3059,
            'users': 3060
        },
        g_httpfilePostURL: `http://${domain}:4010`,
        g_mapObj: {},
        g_toastEL: null,
        g_toastMsg: '',
        g_userdetails: {},
        g_isLoggedIn: false,
        g_districtsList: [],
        g_taluksList: [],
        g_dronenumbers: [],
        g_distGeoFences: [],
        g_talukGeoFences: [],
        g_droneNumbers: [],
        g_routercontainerEl: [],
        g_routeSelectElRef: [],
        g_layerControlsShow: false,
        g_routerViewContainerShow: false,
        g_lineMeasureTool: false,
        g_showPreloader: false,
        g_karnDistTalukBoundsLoaded: false,
        g_karnGPBoundsLoaded: false,
    },
    getters: {
        g_wssDomain (state: any) { return state.g_wssDomain },
        g_portDir (state: any) { return state.g_portDir },
        g_httpfilePostURL (state: any) { return state.g_httpfilePostURL },
        g_mapObj (state: any) { return state.g_mapObj },
        g_toastEL (state: any) { return state.g_toastEL },
        g_toastMsg (state: any) { return state.g_toastMsg },
        g_userdetails (state: any) { return state.g_userdetails },
        g_isLoggedIn (state: any) { return state.g_isLoggedIn },
        g_districtsList (state: any) { return state.g_districtsList },
        g_taluksList (state: any) { return state.g_taluksList },
        g_dronenumbers (state: any) { return state.g_dronenumbers },
        g_distGeoFences (state: any) { return state.g_distGeoFences },
        g_talukGeoFences (state: any) { return state.g_talukGeoFences },
        g_droneNumbers (state: any) { return state.g_droneNumbers },
        g_routercontainerEl (state: any) { return state.g_routercontainerEl },
        g_routeSelectElRef (state: any) { return state.g_routeSelectElRef },
        g_layerControlsShow (state: any) { return state.g_layerControlsShow },
        g_routerViewContainerShow (state: any) { return state.g_routerViewContainerShow },
        g_lineMeasureTool (state: any) { return state.g_lineMeasureTool },
        g_showPreloader (state: any) { return state.g_showPreloader },
        g_karnDistTalukBoundsLoaded (state: any) { return state.g_karnDistTalukBoundsLoaded },
        g_karnGPBoundsLoaded (state: any) { return state.g_karnGPBoundsLoaded },
    },
    mutations: {
        g_mapObj (state: any, value: any) { state.g_mapObj = value },
        g_toastEL (state: any, value: any) { state.g_toastEL = value },
        g_toastMsg (state: any, value: any) { state.g_toastMsg = value },
        g_userdetails (state: any, value: any) { state.g_userdetails = value },
        g_isLoggedIn (state: any, value: any) { state.g_isLoggedIn = value },
        g_districtsList (state: any, value: any) { state.g_districtsList = value },
        g_taluksList (state: any, value: any) { state.g_taluksList = value },
        g_dronenumbers (state: any, value: any) { state.g_dronenumbers = value },
        g_distGeoFences (state: any, value: any) { state.g_distGeoFences = value },
        g_talukGeoFences (state: any, value: any) { state.g_talukGeoFences = value },
        g_droneNumbers (state: any, value: any) { state.g_droneNumbers = value },
        g_routercontainerEl (state: any, value: any) { state.g_routercontainerEl = value },
        g_routeSelectElRef (state: any, value: any) { state.g_routeSelectElRef = value },
        g_layerControlsShow (state: any, value: any) { state.g_layerControlsShow = value },
        g_routerViewContainerShow (state: any, value: any) { state.g_routerViewContainerShow = value },
        g_lineMeasureTool (state: any, value: any) { state.g_lineMeasureTool = value },
        g_showPreloader (state: any, value: any) { state.g_showPreloader = value },
        g_karnDistTalukBoundsLoaded (state: any, value: any) { state.g_karnDistTalukBoundsLoaded = value },
        g_karnGPBoundsLoaded (state: any, value: any) { state.g_karnGPBoundsLoaded = value },
    },
    actions: {
        g_mapObj (context: any, value: any) { context.commit('g_mapObj', value) },
        g_toastEL (context: any, value: any) { context.commit('g_toastEL', value) },
        g_toastMsg (context: any, value: any) { context.commit('g_toastMsg', value) },
        g_userdetails (context: any, value: any) { context.commit('g_userdetails', value) },
        g_isLoggedIn (context: any, value: any) { context.commit('g_isLoggedIn', value) },
        g_districtsList (context: any, value: any) { context.commit('g_districtsList', value) },
        g_taluksList (context: any, value: any) { context.commit('g_taluksList', value) },
        g_dronenumbers (context: any, value: any) { context.commit('g_dronenumbers', value) },
        g_distGeoFences (context: any, value: any) { context.commit('g_distGeoFences', value) },
        g_talukGeoFences (context: any, value: any) { context.commit('g_talukGeoFences', value) },
        g_droneNumbers (context: any, value: any) { context.commit('g_droneNumbers', value) },
        g_routercontainerEl (context: any, value: any) { context.commit('g_routercontainerEl', value) },
        g_routeSelectElRef (context: any, value: any) { context.commit('g_routeSelectElRef', value) },
        g_layerControlsShow (context: any, value: any) { context.commit('g_layerControlsShow', value) },
        g_routerViewContainerShow (context: any, value: any) { context.commit('g_routerViewContainerShow', value) },
        g_lineMeasureTool (context: any, value: any) { context.commit('g_lineMeasureTool', value) },
        g_showPreloader (context: any, value: any) { context.commit('g_showPreloader', value) },
        g_karnDistTalukBoundsLoaded (context: any, value: any) { context.commit('g_karnDistTalukBoundsLoaded', value) },
        g_karnGPBoundsLoaded (context: any, value: any) { context.commit('g_karnGPBoundsLoaded', value) },
    },
}