import store from '@/store';

import globalToast from '@/composables/common/globalToast';

const handleClicks = () => {
    const { showGlobalToast } = globalToast();

    const handleAbadiClicks = (attributesData: any) => {
        loadAbadiInfoFromServer(attributesData);
        loadAbadiFilesListFromServer(attributesData);
    }

    const loadAbadiInfoFromServer = (attributesData: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;
        const { abadilimituuid } = attributesData;

        let requestObj = {
            type: 'abadis',
            request: 'getabadiinfo',
            validateusername: username,
            validatepassword: password,
            abadilimituuid
        };

        console.log(requestObj);

        store.commit('ai_abadifetchstatus', 'Loading Abadi Info... Please Wait...');
        
        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', (err) => {
            console.log(err);
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });

        ws.addEventListener('message', (event) => {
            // console.log(event.data);
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);
        
            if (responseObj.requestStatus == 'success'){
                let { abadiinfo } = responseObj;

                const ai_currentabadi = store.getters.ai_currentabadi;

                let mod_ai_currentabadi = {
                    ...ai_currentabadi,
                    ...abadiinfo
                };

                // console.log(mod_ai_currentabadi);

                store.dispatch('ai_currentabadi', mod_ai_currentabadi);
                store.dispatch('ai_currentabadiInfo', abadiinfo);
                store.dispatch('ai_abadifetchstatus', '');
            } else {
                store.dispatch('ai_abadifetchstatus', 'Error Loading Abadi Info...');
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(Buffer.from(JSON.stringify(requestObj)).toString('base64'));
        });
    }

    const loadAbadiFilesListFromServer = (attributesData: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;
        const { abadilimituuid } = attributesData;

        let requestObj = {
            type: 'files',
            request: 'getabadifileslist',
            validateusername: username,
            validatepassword: password,
            abadilimituuid
        };

        console.log(requestObj);

        store.commit('ai_abadilistfetchstatus', 'Loading Files List... Please Wait...');    
        
        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', (err) => {
            console.log(err);
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });

        ws.addEventListener('message', (event) => {
            // console.log(event.data);
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);
        
            if (responseObj.requestStatus == 'success'){
                let { abadifileslist } = responseObj;

                store.dispatch('af_filesList', abadifileslist);

                if(abadifileslist.length){
                    store.dispatch('ai_abadilistfetchstatus', '');
                } else {
                    store.dispatch('ai_abadilistfetchstatus', 'No Files To Display');
                }
            } else {
                store.dispatch('af_filesList', []);
                store.dispatch('ai_abadilistfetchstatus', 'Error Loading Abadi Files...');
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(Buffer.from(JSON.stringify(requestObj)).toString('base64'));
        });
    }

    return { handleAbadiClicks, loadAbadiInfoFromServer, loadAbadiFilesListFromServer }
}

export default handleClicks;